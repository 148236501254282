import React, { useState, useEffect, useRef } from "react";
import ApiService from "../../utils/Api";
import io from "socket.io-client";
import { GET_TRIPS } from "../../utils/Endpoint";
import { toast } from "react-toastify";
import moment from "moment";
import ReactStars from "react-stars";
import { render } from "react-dom";
import { Router, useNavigate } from "react-router-dom";
import PaymentComponent from "../payment/payment";
import useGMTTime from "../Time/time";
import PaymentCardComponent from "../payWithCard/paymentWithcard";
import { useTimer } from "react-timer-hook";

const SOCKET_SERVER_URL = "https://socket.getservice.ca"; // Your server URL

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: () => setExpired(true),
  });
  const [expired, setExpired] = useState(false);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "25px" }}>
        {!expired ? (
          <>
            <span>{minutes}</span>:<span>{seconds}</span>
          </>
        ) : (
          <div style={{ fontSize: "15px" }}>
            <span>Timer expired</span>
          </div>
        )}
      </div>
      <p>No cancellation fees will be charged before the timer expires.</p>
    </div>
  );
}
const BidFrom = (props) => {
  const navigate = useNavigate();

  const [connected, setConnected] = useState(false);
  const [loggedUser, setLoggedUser] = useState({});
  const [payData, setPayData] = useState();
  const [offers, setOffers] = useState([]);
  const [showCarImage, setShowCarImage] = useState(false);
  const socketRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [trip_Id, setTrip_Id] = useState();
  const [driver_Id, setDriver_Id] = useState();
  const [feebackState, setfeebackState] = useState(true);
  const [orderId, setOrderID] = useState("");
  const [ride, setride] = useState(false);
  const [reason, setReason] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [iosToken, setIosToken] = useState("");
  const [andrToken, setAndrToken] = useState("");
  const [bookingtable, setbookingtable] = useState([]);
  const [walletAmount, setWalletAmount] = useState("");
  const [thank, setThank] = useState(false);
  const [cardPay, setCardPay] = useState(false);
  const [payAmount, setPayAmount] = useState();
  const [rating, setNewRating] = useState();
  const [showLicense, setShowLicense] = useState(false);

  const [feedback, setFeedback] = useState("");
  const [cancelPopup, setcancelPopup] = useState(false);
  const [driverCancel, setDriverCancel] = useState(true);
  const [tripShow, setTripShow] = useState(true);
  const [tripcancel, settripcancel] = useState(false);

  const tripaccept = sessionStorage.getItem("tripaccept");
  const city_id = sessionStorage.getItem("city_id");

  const [cardEndTripPay, setcardEndTripPay] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("driver_Id", driver_Id);
  }, [driver_Id]);

  const handleCancelClick = () => {
    setcancelPopup(true);
  };

  // create Razorpay Order

  const handlePayCardOption = async (res) => {
    setcardEndTripPay(true);
    setTrip_Id(res[0].trip_id);
    const params = {
      amount: res[0].trip_pay_amount,
    };

    // log
    setPayData(res[0]);

    try {
      const response = await fetch(
        `https://apis.yellowtaxi.in/api/v1/razorpay/createorder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      setOrderID(result.resDecrypt.response);
      console.log("Notification sent:", result);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const handlePayCardOptionClose = () => {
    setcardEndTripPay(false);
    setShowLicense(false);
  };

  const [cancelStatus, setCancelStatus] = useState(false);
  const [cancelFee, setcancelFee] = useState(false);

  const handleSubmit = () => {
    setCancelStatus(true);
    setcancelFee(true);
    handleClosepop();
  };

  const handleInputChange = (event) => {
    setFeedback(event.target.value);
  };

  const ratingChanged = (newRating) => {
    setNewRating(newRating);
  };

  const CardPayHandle = () => {
    setCardPay(true);
  };

  const statusValues = {
    request: "Request",
    assigned: "Partner Assigned",
    accept: "Partner Accepted",
    arrive: "Partner Arrived",
    begin: "Begin",
    completed: "Completed",
    cancel: "Cancelled",
    p_cancel_pickup: "Partner Cancel at Pickup Location",
    p_cancel_drop: "Partner Cancel at Drop Location",
    expired: "Expired",
    paid_cancel: "Cancelled",
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getTripHistory();
      handleTripComplete();
    }, 2000); // 20 seconds in milliseconds
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  const csvstatus = { Request: "request" };

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000); // Hide notification after 3 seconds
  };

  const [decline_trip, setdecline_trip] = useState(false);

  const tid = localStorage.getItem("tripid");
  const storageId = localStorage.getItem("trip_id");

  useEffect(() => {
    setTrip_Id(tid);
  }, [tid]);

  const [offerAmt, setOfferAmt] = useState();
  const [otp, setotp] = useState("");
  const api_key = sessionStorage?.getItem("key");
  const [expiryTimestamp, setExpiryTimestamp] = useState(null);

  const savedTimer = localStorage.getItem("timer");

  // const cityd = localStorage.getItem("cityInfo");
  // let cityInfo;

  // if (cityd) {
  //   try {
  //     cityInfo = JSON.parse(cityd);
  //   } catch (error) {
  //     console.error("Invalid JSON:", error);
  //   }
  // } else {
  //   console.error("No city data found");
  // }
  var d = new Date();
  var formattedTime = moment.utc(d).format("YYYY-MM-DD HH:mm:ss");
  const trip_pay_mode = sessionStorage.getItem("trip_pay_mode");
  // const { formattedTime } = useGMTTime(cityInfo);

  const handleCloseTripDetail = () => {
    sessionStorage.removeItem("ride");
    localStorage.removeItem("tripid");
    localStorage.removeItem("trip_id");
    settripcancel(false);
    setTripShow(false);
    setcancelPopup(false);
    setThank(false);
    setride(false);

    setcardEndTripPay(false);
    setOffers([]);
    setdecline_trip(false);
    setcancelFee(false);
    setReason("");
    setDriverCancel(true);
    closePayHandle(false);
    handleClosepop(false);
    setride(false);
    setCancelStatus(false);
    setShowPopup(false);
    window.location.reload();
  };

  const handlePayClick = () => {
    setShowPopup(!showPopup);
  };
  // console.log("Notification sent:", response);

  const declineNotificationApi = async () => {
    if (reason?.length < 10) {
      toast.dismiss();
      toast.error("Reason should be minimum 10 words. Please fill it out.");
      return;
    }

    const params = {
      trip_id: csvData[0].trip_id,
      trip_status: "cancel",
      trip_reason: reason,
      driver_id: csvData[0].driver_Id,
      is_cancelled: 1,
      is_return_details: 1,
      can_fee_by: "r",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/tripapi/updatetrip?api_key=${api_key}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      localStorage.removeItem("tripid");

      setCancelStatus(true);
      // setcancelFee(true);
      // handleClosepop();
      // sessionStorage.removeItem("ride");
      // setride(false);
      // setcardEndTripPay(false);
      // setOffers([]);
      // setdecline_trip(false);
      // setcancelFee(false);
      // setReason("");
      // setDriverCancel(true);
      // settripcancel(true);
      // closePayHandle(false);
      // handleClosepop(false);

      sessionStorage.setItem("payStatus", true);
      setCancelStatus(true);
      // setcancelFee(true);
      handleClosepop();
      sessionStorage.removeItem("ride");
      setride(false);
      // setcardEndTripPay(false);
      setOffers([]);
      // setdecline_trip(false);
      // setcancelFee(false);
      setReason("");
      setDriverCancel(true);
      settripcancel(true);
      // closePayHandle(false);
      // handleClosepop(false);

      const Params = {
        trip_id: csvData[0].trip_id,
        trip_status: "cancel",
        sound: "driver_cancel.caf",
        android:
          csvData[0].Driver.d_device_type == "Android"
            ? csvData[0].Driver.d_device_token
            : "",
        ios:
          csvData[0].Driver.d_device_type.toLowerCase() == "ios"
            ? csvData[0].Driver.d_device_token
            : "",
        to: "driver",
        message: "Rider has cancelled the Ride.",
      };
      try {
        const response = await fetch(
          `https://apis.yellowtaxi.in/api/v1/push2/driver-android-ios-push-notification?api_key=${api_key}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(Params),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        setdecline_trip(false);
        // window.location.reload();
      } catch (error) {
        console.error("Error sending notification:", error);
      }
      setdecline_trip(false);
      // console.log("Notification sent:", result);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const userid = sessionStorage.getItem("userid");

  const handleClose = () => {
    sessionStorage.removeItem("ride");
    setcancelPopup(false);
    setThank(false);
    setride(false);
    localStorage.removeItem("tripid");
    localStorage.removeItem("trip_id");

    setcardEndTripPay(false);
    setOffers([]);
    setdecline_trip(false);
    setcancelFee(false);
    setReason("");
    setDriverCancel(true);
    closePayHandle(false);
    handleClosepop(false);
    setride(false);
    setCancelStatus(false);
    setShowPopup(false);
    window.location.reload();
  };

  const handleClosepop = () => {
    setcancelPopup(false);
    // setReason("");
  };

  const HandleDriverId = (id) => {
    setDriver_Id(id);
  };

  // console.log(ridestart);
  const getTripHistory = async () => {
    const data = {
      is_request: "0",
      offset: "0",
      limit: "10",
      statuses:
        "completed,paid_cancel,begin,assign,arrive,accept,request,paid,cancel,p_cancel_drop,p_cancel_pickup,expired",
    };
    if (api_key && userid) {
      try {
        const response = await ApiService.post(
          `${GET_TRIPS}?api_key=${api_key}&user_id=${userid}`,
          data
        );
        if (response.status === 200) {
          setbookingtable(response.data.resDecrypt.response);
          if (tid == response.data.resDecrypt.response[0].trip_id) {
            // console.log(tid == response.data.response[0].trip_id)
            //  setTripDone(false);
            // sessionStorage.removeItem("ride");
            // setride(false)
          }
          // console.log(response.data.response)
        }
        if (response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
        }
      } catch (error) {
        console.log("Error::", error);
        // localStorage.clear();
        // sessionStorage.clear();
      }
    }
  };

  const PaidWitCash = async (res) => {
    const data = {
      trip_pay_status: "Initiated",
      trip_id: res[0].trip_id,
      trip_pay_mode: "Cash",
      is_use_wallet: "0",
    };
    try {
      const response = await ApiService.post(
        `/tripapi/updatetrip?api_key=${api_key}`,
        data
      );
      if (response.status === 200) {
        setride(false);

        // setThank(true);
        // console.log(response.data.response)

        let Param =
          res[0].Driver.d_device_type === "Android"
            ? {
                trip_id: res[0].trip_id,
                trip_status: "Initiated",
                android: res[0].Driver.d_device_token,
                message: "Rider is paying cash",
              }
            : {
                trip_id: res[0].trip_id,
                trip_status: "Initiated",
                ios: res[0].Driver.d_device_token,
                message: "Rider is paying cash",
              };

        const params = new URLSearchParams({
          api_key: api_key,
          ...Param, // Assumes `Params` is an object with key-value pairs
        });

        try {
          const response = await fetch(
            `https://apis.yellowtaxi.in/api/v1/push2/driver-android-ios-push-notification?${params.toString()}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const result = await response.json();

          toast.success(
            "Please wait for confirmation, as your request has been initiated."
          );

          // window.location.reload();
        } catch (error) {
          console.log("Error::", error);
          toast.error(
            "Please select a different payment method, as we are currently unable to proceed with this one."
          );
        }
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  let csvData = bookingtable?.map((it, ind) => {
    var e = new Date(it.trip_date);
    var utc_offset_gmt = e.getTimezoneOffset();
    e.setMinutes(e.getMinutes() - utc_offset_gmt);
    it.date = moment(e).format("YYYY-MM-DD");
    it.timefor = moment(e).format("hh:mm a");

    // it.date = moment(it.trip_date).format("YYYY-MM-DD");
    // it.timefor = moment(it.trip_date).format("hh:mm A");
    it.status = statusValues[it.trip_status];
    return it;
  });

  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    if (
      csvData[0]?.trip_status == "accept" ||
      csvData[0]?.trip_status == "begin" ||
      csvData[0]?.trip_status == "arrive" // Check if toast has already been shown
    ) {
      sessionStorage.removeItem("ride");
      localStorage.removeItem("tripid");
    }
  }, [csvData]);

  useEffect(() => {
    csvData?.forEach((item) => {
      if (
        item.trip_id === trip_Id &&
        (item.trip_status === "paid_cancel" ||
          item.trip_status === "cancel" ||
          item.trip_status === "p_cancel_pickup" ||
          item.trip_status === "p_cancel_drop") &&
        tid &&
        !toastShown // Check if toast has already been shown
      ) {
        setDriverCancel(false);
        if (!tripcancel) {
          sessionStorage.removeItem("ride");
          localStorage.removeItem("tripid");
        }
        if (trip_pay_mode == "Card") {
          sessionStorage.removeItem("ride");
          localStorage.removeItem("tripid");
        }

        toast.error("Your ride has been canceled.");
        setToastShown(true); // Set toastShown to true after showing the toast
      }
    });
  }, [csvData, toastShown, trip_Id, tid, tripcancel]);

  useEffect(() => {
    csvData?.forEach((item) => {
      if (
        item.trip_id === trip_Id &&
        item.trip_status === "completed" &&
        !thank &&
        tid &&
        !toastShown
      ) {
        setDriverCancel(false);
        sessionStorage.removeItem("ride");
        localStorage.removeItem("tripid");
        setThank(true);
        setToastShown(true);
        // Set toastShown to true after showing the toast
      }
    });
  }, [csvData, trip_Id, tid, tripcancel]);

  //Paid With Wallet

  const wamt = sessionStorage.getItem("wmt");

  var walletAmt = 0;

  const payWithWallet = async (res) => {
    // Step 1: Set parameters for the transaction check request
    const params = {
      limit: 1,
      offset: 0,
    };

    try {
      // Step 2: Fetch the wallet amount from API
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/tronsactionapi/gettransactions?api_key=${api_key}&user_id=${userid}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      const result = await response.json();

      // Step 3: Update wallet amount in state
      const walletBalance = Number(result.resDecrypt.response[0].r_cur_bal);
      setWalletAmount(walletBalance);

      // Step 4: Check if wallet amount is sufficient for the payment
      const tripPayAmount = Number(res[0].trip_pay_amount);
      if (walletBalance < tripPayAmount) {
        toast.error("Insufficient wallet amount");
        return;
      }

      // Step 5: Prepare transaction data for payment
      const transactionData = {
        trip_id: res[0].trip_id,
        driver_id: res[0].driver_Id,
        pay_mode: "wallet",
        pay_amount: tripPayAmount,
        total_amt: tripPayAmount,
        rider_amt: tripPayAmount,
        promo_amt: res[0].trip_promo_amt,
        commission_amt: res[0].trip_comp_commision,
        trip_driver_commision: res[0].trip_driver_commision,
        trans_desc: "Trip Payment",

        d_curr: "INR",
        r_curr: "INR",

        curr_multiplier: 1,
      };

      // Step 6: Send the payment request
      const paymentResult = await ApiService.post(
        `/tronsactionapi/addtriptrans?api_key=${api_key}&&user_id=${userid}`,
        transactionData
      );

      if (paymentResult?.status === 200) {
        // Step 7: Prepare data to update the trip status
        toast.success(
          "Please wait for confirmation, as your request has been initiated."
        );

        const formattedTime = new Date().toISOString(); // assuming `formattedTime` needs to be current time in ISO
        const updateTripData = {
          trip_id: res[0].trip_id,
          trip_status: "completed",
          trip_pay_status: "Paid",
          trip_pay_date: formattedTime,
          trip_pay_mode: "wallet",
        };

        // Step 8: Send the request to update the trip status
        const updateTripResult = await ApiService.post(
          `/tripapi/updatetrip?api_key=${api_key}`,
          updateTripData
        );

        if (updateTripResult.status === 200) {
          // Step 9: Reset UI state after successful payment and trip update

          setride(false);
          setCancelStatus(false);
          setThank(true);
          sessionStorage.removeItem("ride");
          setcardEndTripPay(false);
          setOffers([]);
          setdecline_trip(false);
          setcancelFee(false);
          setReason("");
          setDriverCancel(true);
          settripcancel(true);
          closePayHandle(false);
          handleClosepop(false);
          setShowPopup(false);
        }
      }
    } catch (error) {
      // Error handling for both API requests
      console.error("Error:", error);
    }

    // Log wallet amount (for debugging purposes)
    console.log(walletAmount);
  };

  const declinewithcard = async () => {
    sessionStorage.setItem("tripaccept", JSON.stringify(csvData[0]));

    const data = {
      trip_id: JSON.parse(tripaccept).trip_id,
      trip_pay_status: "Paid",
      trip_pay_date: formattedTime,
      trip_pay_mode: "Card",
    };
    try {
      const response = await ApiService.post(
        `/tripapi/updatetrip?api_key=${api_key}`,
        data
      );
      if (response.status === 200) {
        setride(false);
        setCancelStatus(false);
        sessionStorage.removeItem("ride");
        setride(false);
        setcardEndTripPay(false);
        setOffers([]);
        setdecline_trip(false);
        setcancelFee(false);
        setReason("");
        setDriverCancel(true);
        settripcancel(true);
        closePayHandle(false);
        handleClosepop(false);
        setride(false);
        setCancelStatus(false);
        setShowPopup(false);
        // setThank(true);

        // console.log(response.data.response)
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  const [dlImage, setDLImage] = useState("");

  const driverLicenseHandler = async (driver_Id) => {
    const data = {
      driver_id: driver_Id,
      asset_type: "LICENSE",
    };

    if (driver_Id) {
      setShowLicense(true);

      try {
        const response = await ApiService.post(
          `driverassetapi/getdriverassets?api_key=${api_key}`,
          data
        );
        if (response.status === 200) {
          console.log(response.data.resDecrypt.response);

          const frontImage = response.data.resDecrypt.response.find(
            (element) => element.is_front == "1"
          );
          setDLImage(frontImage ? frontImage.img_path : "null");
        }
      } catch (error) {
        console.log("Error::", error);
      }
    }
  };

  const payWithWalletCancel = async (tripId) => {
    if (Number(wamt) < Number(JSON.parse(tripaccept).trip_pay_amount)) {
      // console.log(wamt, JSON.parse(tripaccept).trip_pay_amount);
      toast.error("Insufficient wallet amount");
    } else {
      try {
        let data = {
          trip_id: tripId,
          driver_id: driver_Id,
          pay_mode: "wallet",
          pay_amount: JSON.parse(tripaccept).trip_pay_amount,
          total_amt: JSON.parse(tripaccept).trip_pay_amount,
          rider_amt: JSON.parse(tripaccept).trip_pay_amount,
          promo_amt: JSON.parse(tripaccept).trip_promo_amt,
          commission_amt: JSON.parse(tripaccept).trip_comp_commision,
          trip_driver_commision: JSON.parse(tripaccept).trip_driver_commision,
          trans_description: "Trip Payment",
        };

        const result = await ApiService.post(
          `/transactionapi/addtriptrans?api_key=${api_key}&&user_id=${userid}`,
          data
        );

        if (result?.status === 200) {
          sessionStorage.removeItem("ride");
          setride(false);
          setcardEndTripPay(false);
          setOffers([]);
          setdecline_trip(false);
          setcancelFee(false);
          setReason("");
          setDriverCancel(true);
          settripcancel(true);
          closePayHandle(false);
          handleClosepop(false);
          setCancelStatus(false);
          setShowPopup(false);

          const data = {
            trip_id: tripId,
            trip_pay_status: "Paid",
            trip_pay_date: formattedTime,
            trip_pay_mode: "wallet",
          };
          try {
            const response = await ApiService.post(
              `/tripapi/updatetrip?api_key=${api_key}`,
              data
            );
            if (response.status === 200) {
              setride(false);
              setCancelStatus(false);
              setThank(true);

              // console.log(response.data.response)
            }
          } catch (error) {
            console.log("Error::", error);
          }
        }
      } catch (error) {
        console.log("Error::", error);
      }
    }
  };

  // payWithCard Handler

  const CardHandler = async (response) => {
    try {
      let data = {
        trip_id: payData.trip_id,
        driver_id: payData.driver_Id,
        pay_mode: "Card",
        d_curr: "INR",
        is_change_paid: 1,
        r_curr: "INR",
        pay_amount: payData.trip_pay_amount,
        total_amt: payData.trip_pay_amount,
        rider_amt: payData.trip_pay_amount,
        promo_amt: payData.trip_promo_amt,
        commission_amt: payData.trip_comp_commision,
        trip_driver_commision: payData.trip_driver_commision,
        trans_desc: "Trip Payment",
        curr_multiplier: 1,
        exc_dt: response,
      };

      const result = await ApiService.post(
        `/tronsactionapi/addtriptrans?api_key=${api_key}&&user_id=${userid}`,
        data
      );

      if (result?.status === 200) {
        toast.success(
          "Please wait for confirmation, as your request has been initiated."
        );

        sessionStorage.removeItem("ride");
        setride(false);
        setcardEndTripPay(false);
        setOffers([]);
        setdecline_trip(false);
        setcancelFee(false);
        setReason("");
        setDriverCancel(true);
        settripcancel(true);
        closePayHandle(false);
        handleClosepop(false);
        setCancelStatus(false);
        setShowPopup(false);

        const data = {
          trip_id: payData.trip_id,
          trip_pay_status: "Paid",
          trip_pay_date: formattedTime,
          trip_pay_mode: "Card",
        };
        try {
          const response = await ApiService.post(
            `/tripapi/updatetrip?api_key=${api_key}`,
            data
          );
          if (response.status === 200) {
            setride(false);
            setCancelStatus(false);
            setThank(true);

            // console.log(response.data.response)
          }
        } catch (error) {
          console.log("Error::", error);
        }
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  // End with Card

  const closePayHandle = () => {
    setCardPay(false);
    setcardEndTripPay(false);
  };

  const sendFeedback = async (trip) => {
    const data = {
      trip_id: trip.trip_id,
      trip_feedback: feedback,
      api_key: api_key,
      trip_rating: rating,
    };

    let totalRating = 0;

    // Check if d_rating and d_rating_count are defined and calculate the new rating
    if (
      trip.Driver?.d_rating !== undefined &&
      trip.Driver?.d_rating_count !== undefined
    ) {
      totalRating =
        (parseFloat(trip.Driver.d_rating) *
          parseFloat(trip.Driver.d_rating_count) +
          parseFloat(rating)) /
        (parseFloat(trip.Driver.d_rating_count) + 1.0);
    } else {
      // Fallback in case d_rating or d_rating_count is not defined
      totalRating = rating;
    }

    const Driverdata = {
      driver_id: trip.Driver?.driver_id,
      api_key: api_key,
      d_rating: totalRating,
      d_rating_count: parseFloat(trip.Driver?.d_rating_count || 0) + 1.0,
    };

    try {
      const response = await ApiService.post(
        `/driverapi/updatedriverprofile?api_key=${api_key}`,
        Driverdata
      );
      if (response.status === 200) {
        setride(false);
        setfeebackState(false);
        // console.log(response.data.response)
      }
    } catch (error) {
      console.log("Error::", error);
    }

    try {
      const response = await ApiService.post(
        `/tripapi/updatetrip?api_key=${api_key}`,
        data
      );
      if (response.status === 200) {
        setride(false);
        setfeebackState(false);
        // console.log(response.data.response)
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  const [tripDone, setTripDone] = useState(false);

  // console.log(tid)

  const handleTripComplete = () => {
    if (tid == csvData[0]?.id) {
      if (
        csvData[0]?.trip_status == "completed" ||
        csvData[0]?.trip_status == "cancel"
      ) {
        setTripDone(false);
        sessionStorage.removeItem("ride");
        setride(false);
      }
    }
  };

  const handleClosefees = () => {
    setcancelFee(false);
  };

  /*   useEffect(() => {
    const intervalId = setInterval(() => {
      handleTripComplete();
    }, 2000); // 20 seconds in milliseconds
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); */

  useEffect(() => {
    if (
      cardPay ||
      cardEndTripPay ||
      (csvData[0] &&
        ["accept", "begin", "arrive", "cancel"].includes(
          csvData[0].trip_status
        ) &&
        csvData.length > 1 &&
        csvData[0].trip_pay_status !== "Paid" &&
        csvData[0].trip_status !== "cancel" &&
        csvData[0].trip_pay_amount !== "0")
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [cardEndTripPay, cardPay, csvData]);

  const handleShowCar = () => {
    setShowCarImage(true);
  };

  const handleCloseShowCar = () => {
    setShowCarImage(false);
  };

  //set cancel timer

  useEffect(() => {
    if (csvData[0]?.trip_status == "accept") {
      if (savedTimer) {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 60 * savedTimer); // Set timer based on the parsed value
        setExpiryTimestamp(time);
      }
    }
  }, [csvData]);

  // useEffect(() => {
  //   let timeoutId;

  //   if (csvData[0]?.trip_status == "request") {
  //     console.log("Setting timeout for 40 seconds");
  //     timeoutId = setTimeout(() => {
  //       console.log("Calling getExpired()");
  //       getExpired();
  //     }, 40 * 1000); // 40 seconds in milliseconds
  //   } else {
  //     console.log("Condition not met, timeout not set");
  //   }

  //   return () => clearTimeout(timeoutId);
  // }, [tid]); // Dependency array

  const getExpired = async () => {
    try {
      let data = {
        trip_id: csvData[0]?.trip_id,
        trip_status: "expired",
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/tripapi/updatetrip?api_key=${api_key}&&user_id=${userid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      localStorage.removeItem("tripid");
      localStorage.removeItem("now"); // Clear the stored time after expiration

      setdecline_trip(false);
      toast.error("Sorry, your ride has been expired");
      // window.location.reload();
      const result = await response.json();

      // console.log("Notification sent:", result);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const timeoutSet = useRef(false); // Prevent multiple timeouts

  // Retrieve the GMT time stored as "now" in localStorage
  const storedTimeString = localStorage.getItem("now");
  console.log("Stored Time:", storedTimeString);

  if (storedTimeString) {
    // Parse the stored time and calculate the target expiration time
    const storedTime = new Date(storedTimeString).getTime();
    const targetExpirationTime = storedTime + 40 * 1000; // 40 seconds after stored time
    const currentTime = Date.now();

    // Calculate remaining time until expiration
    const remainingTime = targetExpirationTime - currentTime;

    // Debugging output
    console.log("Stored Time:", storedTimeString);
    console.log("Parsed Stored Time (ms):", storedTime);
    console.log("Target Expiration Time (ms):", targetExpirationTime);
    console.log("Current Time (ms):", currentTime);
    console.log("Remaining Time (ms):", remainingTime);

    if (remainingTime <= 0) {
      // If the expiration time has already passed, call getExpired() immediately
      console.log("Expiration time has passed, calling getExpired()");
      getExpired();
      localStorage.removeItem("now"); // Clear the stored time
    } else if (!timeoutSet.current && csvData[0]?.trip_status === "request") {
      // Set a timeout to call getExpired() at the correct expiration time
      console.log(`Setting timeout for ${remainingTime / 1000} seconds`);
      setTimeout(() => {
        console.log("Calling getExpired()");
        getExpired();
        timeoutSet.current = false; // Reset if needed for future calls
      }, remainingTime);

      timeoutSet.current = true; // Mark that the timeout has been set
    }
  } else {
    // console.log(
    //   "No stored time found in localStorage. Make sure 'now' is set by another function."
    // );
  }

  const trackingUrl = (tripId) => {
    window.open(
      `https://images.yellowtaxi.in/MapRoute/index.php?trip=${tripId}`,
      "_blank"
    );
  };

  return (
    <div>
      {csvData
        ?.filter(
          (item) =>
            item?.trip_status === "accept" ||
            item?.trip_status === "begin" ||
            item?.trip_status === "arrive"
        )
        .map((filteredItem, index) => (
          <div className="backdrop" key={index}>
            <div className="center-screen" style={{ minWidth: "350px" }}>
              <div className="offercount">
                <div style={{ display: "grid", width: 300 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                      justifyContent: "center",
                    }}
                  >
                    {filteredItem?.trip_status === "accept"
                      ? "Driver Assigned"
                      : filteredItem?.trip_status === "arrive"
                      ? "Driver Arrived"
                      : "Begin"}
                  </div>
                  <hr style={{ width: "100%" }} />
                  {expiryTimestamp && filteredItem?.trip_status !== "begin" && (
                    <MyTimer expiryTimestamp={expiryTimestamp} />
                  )}
                  {filteredItem?.trip_status !== "begin" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div>OTP:</div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          marginLeft: "5px",
                        }}
                      >
                        {filteredItem?.otp}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={`https://images.yellowtaxi.in/originals/${
                        filteredItem?.Driver?.d_profile_image_path ||
                        "default-image.png"
                      }`}
                      alt="Driver Profile"
                      width={100}
                      height={100}
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <table
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      width: "100%",
                      height: "150px",
                      textAlign: "left",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{ paddingRight: "10px", fontWeight: "bold" }}
                        >
                          Driver Name:
                        </td>
                        <td style={{ fontSize: "18px", fontWeight: "500" }}>
                          {filteredItem?.Driver?.d_name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingRight: "10px", fontWeight: "bold" }}
                        >
                          Driver Contact:
                        </td>
                        <td style={{ fontSize: "18px", fontWeight: "500" }}>
                          {filteredItem?.Driver?.d_phone}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingRight: "10px", fontWeight: "bold" }}
                        >
                          Car Name:
                        </td>
                        <td style={{ fontSize: "18px", fontWeight: "500" }}>
                          {filteredItem?.Driver?.car_name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingRight: "10px", fontWeight: "bold" }}
                        >
                          Car Reg No:
                        </td>
                        <td style={{ fontSize: "18px", fontWeight: "500" }}>
                          {filteredItem?.Driver?.car_reg_no}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <h5>License: </h5>
                    <button
                      style={{
                        borderRadius: "12px",
                        backgroundColor: "#f1c40f",
                        marginLeft: "33px",
                        width: "100px",
                        height: "30px",
                        color: "black",
                        fontWeight: 500,
                      }}
                      onClick={() =>
                        driverLicenseHandler(filteredItem?.driver_id)
                      }
                    >
                      View
                    </button>
                  </div>

                  {showLicense && (
                    <div className="backdrop">
                      <div className="center-screen">
                        <div className="popup">
                          <div className="container-wrapper">
                            <div className="popup-inner">
                              <div
                                className="tripcancle"
                                style={{ marginTop: "20px" }}
                              >
                                <img
                                  src={`https://images.yellowtaxi.in/originals/${dlImage}`}
                                  alt="Driver License"
                                  style={{
                                    borderRadius: "4px",
                                    maxWidth: "330px",
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  style={{
                                    background: "black",
                                    color: "white",
                                    padding: "5px 10px",
                                  }}
                                  onClick={handlePayCardOptionClose}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <h5>Car Image: </h5>
                    <button
                      style={{
                        borderRadius: "12px",
                        backgroundColor: "#f1c40f",
                        marginLeft: "18px",
                        width: "100px",
                        height: "30px",
                        color: "black",
                        fontWeight: 500,
                      }}
                      onClick={handleShowCar}
                    >
                      View
                    </button>
                  </div>

                  {filteredItem?.trip_status == "begin" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <h5>Tracking: </h5>
                      <button
                        style={{
                          borderRadius: "12px",
                          backgroundColor: "#f1c40f",
                          marginLeft: "28px",
                          width: "100px",
                          height: "30px",
                          color: "black",
                          fontWeight: 500,
                        }}
                        onClick={() => trackingUrl(filteredItem?.trip_id)}
                      >
                        View
                      </button>
                    </div>
                  )}
                  {showCarImage && (
                    <div className="backdrop">
                      <div className="center-screen">
                        <div className="popup">
                          <div className="container-wrapper">
                            <div className="popup-inner">
                              <div
                                className="tripcancle"
                                style={{ marginTop: "20px", display: "grid" }}
                              >
                                <img
                                  src={`https://images.yellowtaxi.in/originals/${filteredItem?.Driver?.car_front_image_path}`}
                                  alt="Car Image"
                                  style={{
                                    borderRadius: "4px",
                                    maxWidth: "330px",
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  style={{
                                    background: "black",
                                    color: "white",
                                    padding: "5px 10px",
                                  }}
                                  onClick={handleCloseShowCar}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {filteredItem?.trip_status !== "begin" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                      }}
                    >
                      <button
                        style={{
                          background: "black",
                          color: "white",
                          padding: "5px 10px",
                        }}
                        onClick={handleCancelClick}
                      >
                        Cancel Ride
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

      {csvData?.some(
        (item) => item?.trip_status === "completed" && item.trip_id == storageId
      ) && (
        <div className="backdrop">
          <div className="center-screen">
            <div className="offercount">
              <div
                style={{
                  marginBottom: "20px",
                  padding: "25px",
                }}
              >
                Thank You For using YellowTaxi!
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    background: "black",
                    color: "white",
                  }}
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {cancelPopup && (
        <>
          <div className="backdrop">
            <div className="center-screen ">
              <div
                className="popup"
                style={{ boxShadow: "0px 0px 0px 1px black" }}
              >
                <div className="container-wrapper">
                  <div className="popup-inner">
                    <div className="tripcancle" style={{ marginTop: "20px" }}>
                      Reason:{" "}
                      <input
                        className="Noteinput"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        style={{ marginLeft: "5px", width: "60%" }}
                      />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        style={{
                          background: "black",
                          color: "white",
                          padding: "5px 10px 5px 10px",
                          marginRight: "10px",
                        }}
                        onClick={declineNotificationApi}
                      >
                        Submit
                      </button>
                      <button
                        style={{
                          background: "grey",
                          color: "white",
                          padding: "5px 10px 5px 10px",
                        }}
                        onClick={handleClosepop}
                      >
                        Close
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {cardEndTripPay && (
        <>
          <div className="backdrop" />
          <div
            className="popup-overlay"
            style={{ zIndex: 99999, paddingBottom: "50px" }}
          >
            <div className="popup-content">
              <div
                style={{
                  float: "right",
                }}
              >
                <button
                  style={{
                    background: "black",
                    color: "white",
                    padding: "5px 20px",
                  }}
                  onClick={closePayHandle}
                >
                  X
                </button>
              </div>
              <PaymentComponent orderID={orderId} apiHandler={CardHandler} />
            </div>
          </div>
        </>
      )}

      {/*    {cancelFee && trip_pay_mode !== "Card" && (
        <div className="popup-container">
          <div
            className="popup4"
            style={{ boxShadow: "0px 0px 0px 1px black" }}
          >
            <select
              style={{
                width: "190px",
                padding: "3px",
                border: "1px solid #eee",
              }}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "cash") {
                  PaidWitCash();
                } else if (selectedValue === "card") {
                  handlePayCardOption();
                  handleClosefees();
                } else if (selectedValue === "wallet") {
                  payWithWallet();
                  handleClosefees();
                }
              }}
            >
              <option selected disabled>
                Select Payment Method
              </option>
              {city_id == cityInfo.city_id && (
                <option value="wallet">Wallet</option>
              )}
              <option value="card">Card</option>
            </select>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "black",
                  color: "white",
                  padding: "5px 10px 5px 10px",
                }}
                onClick={handleClosefees}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
 */}

      {csvData
        .filter(
          (item) =>
            (item.trip_status === "cancel" ||
              item.trip_status === "paid_cancel" ||
              item.trip_status === "completed") &&
            item.trip_id == storageId
        )
        .map((filteredItem) => (
          <div className="popups-container">
            <div className="backdrop">
              <React.Fragment key={filteredItem.trip_id}>
                <div className="center-screen" style={{ minHeight: "365px" }}>
                  <div className="offercount">
                    <div style={{ display: "grid" }}>
                      <div style={{ display: "grid", position: "relative" }}>
                        <div className="dot red-dot" />
                        <div style={{ marginLeft: "20px" }}>
                          Pickup Location:
                          <div className="loc" style={{ display: "grid" }}>
                            {filteredItem.trip_from_loc}
                          </div>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="dot green-dot" />
                        <div style={{ marginLeft: "20px" }}>
                          Drop Location:
                          <div className="loc">{filteredItem.trip_to_loc}</div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        Status:
                        <span style={{ fontWeight: "700", marginLeft: "5px" }}>
                          {filteredItem.trip_pay_status
                            ? filteredItem.trip_pay_status
                            : ""}
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        Pay Amount:
                        <span style={{ fontWeight: "700", marginLeft: "5px" }}>
                          {"INR"} {filteredItem.trip_pay_amount}
                        </span>
                      </div>

                      {feebackState && filteredItem == "completed" ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <ReactStars
                              count={5}
                              edit={true}
                              value={rating}
                              isHalf={true}
                              a11y={true}
                              activeColor="#ffd700"
                              onChange={ratingChanged}
                              size={25}
                              color2={"#ffd700"}
                            />
                          </div>
                          <div
                            className="feedback"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            Feedback:
                            <input
                              type="text"
                              onChange={handleInputChange}
                              className="Noteinput"
                              style={{ marginLeft: "5px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <button
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 20px",
                              }}
                              onClick={() => sendFeedback(filteredItem)}
                            >
                              Submit Feedback
                            </button>
                          </div>
                        </>
                      ) :  (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                         {filteredItem === "completed" ? "Thank You For Your Valuable Feedback" : ""}  
                        </div>
                      )}

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          onClick={handleCloseTripDetail}
                          style={{
                            background: "black",
                            color: "white",
                            padding: "5px 20px",
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        ))}

      {csvData &&
        csvData.length > 0 &&
        csvData[0].trip_pay_status?.toLowerCase() != "paid" &&
        csvData[0].trip_pay_mode !== "Card" &&
        csvData[0].trip_pay_amount != "0" &&
        csvData[0]?.trip_status !== "cancel" &&
        csvData[0].trip_status !== "accept" &&
        csvData[0].trip_status !== "begin" &&
        csvData[0].trip_status !== "arrive" &&
        csvData[0].trip_status !== "request" && (
          <>
            <div class="popups-container">
              <div className="backdrop">
                {csvData[0]?.trip_status == "cancel" ||
                csvData[0]?.trip_status === "paid_cancel" ||
                csvData[0]?.trip_status == "completed" ? (
                  <React.Fragment key={csvData[0].trip_id}>
                    <div
                      className="center-screen"
                      style={{ minHeight: "365px" }}
                    >
                      <div className="offercount">
                        <div style={{ display: "grid" }}>
                          <div
                            style={{ display: "grid", position: "relative" }}
                          >
                            <div className="dot red-dot" />
                            <div style={{ marginLeft: "20px" }}>
                              Pickup Location:
                              <div className="loc" style={{ display: "grid" }}>
                                {csvData[0].trip_from_loc}
                              </div>
                            </div>
                            <div className="vertical-line"></div>
                            <div className="dot green-dot" />
                            <div style={{ marginLeft: "20px" }}>
                              Drop Location:
                              <div className="loc">
                                {csvData[0].trip_to_loc}
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            Status:
                            <span
                              style={{ fontWeight: "700", marginLeft: "5px" }}
                            >
                              {csvData[0].trip_pay_status
                                ? csvData[0].trip_pay_status
                                : "Unpaid"}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            Pay Amount:
                            <span
                              style={{ fontWeight: "700", marginLeft: "5px" }}
                            >
                              {"INR"} {csvData[0].trip_pay_amount}
                            </span>
                          </div>

                          {feebackState ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <ReactStars
                                  count={5}
                                  edit={true}
                                  value={rating}
                                  isHalf={true}
                                  a11y={true}
                                  activeColor="#ffd700"
                                  onChange={ratingChanged}
                                  size={25}
                                  color2={"#ffd700"}
                                />
                              </div>
                              <div
                                className="feedback"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                Feedback:
                                <input
                                  type="text"
                                  onChange={handleInputChange}
                                  className="Noteinput"
                                  style={{ marginLeft: "5px" }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <button
                                  style={{
                                    background: "black",
                                    color: "white",
                                    padding: "5px 20px",
                                  }}
                                  onClick={() => sendFeedback(csvData[0])}
                                >
                                  Submit Feedback
                                </button>
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              Thank You For Your Valuable Feedback
                            </div>
                          )}

                          {csvData &&
                          csvData[0]?.trip_pay_status?.toLowerCase() !==
                            "paid" &&
                          csvData[0]?.trip_pay_amount !== "0" ? (
                            <>
                              {showPopup && trip_pay_mode !== "Card" && (
                                <div className="backdrop">
                                  <div className="center-screen">
                                    <div
                                      className="popup"
                                      style={{
                                        boxShadow: "0px 0px 0px 1px black",
                                        display: "grid",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <select
                                        style={{
                                          width: "190px",
                                          padding: "3px",
                                          border: "1px solid black",
                                        }}
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          if (selectedValue === "cash") {
                                            PaidWitCash(csvData);
                                            handlePayClick();
                                          } else if (selectedValue === "card") {
                                            handlePayCardOption(csvData);
                                            handlePayClick();
                                          } else if (
                                            selectedValue === "wallet"
                                          ) {
                                            payWithWallet(csvData);
                                            handlePayClick();
                                          }
                                        }}
                                      >
                                        <option selected disabled>
                                          Select Payment Method
                                        </option>
                                        <option value="cash">Cash</option>

                                        <option value="wallet">Wallet</option>
                                        <option value="card">Card</option>
                                      </select>
                                      <button
                                        onClick={handlePayClick}
                                        style={{
                                          marginTop: 20,
                                          background: "black",
                                          color: "white",
                                          padding: "5px 20px",
                                        }}
                                      >
                                        Close
                                      </button>
                                    </div>{" "}
                                  </div>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  onClick={
                                    trip_pay_mode !== "Card"
                                      ? handlePayClick
                                      : handleCloseTripDetail
                                  }
                                  style={{
                                    background: "black",
                                    color: "white",
                                    padding: "5px 20px",
                                  }}
                                >
                                  {trip_pay_mode !== "Card" ? "Pay" : "Close"}
                                </button>
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                onClick={handleCloseTripDetail}
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "5px 20px",
                                }}
                              >
                                Close
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default BidFrom;
